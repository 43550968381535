import { Facebook } from '@finn/design-system/icons/facebook';
import { Link } from '@finn/design-system/icons/link';
import { SoicialLinkedin } from '@finn/design-system/icons/soicial-linkedin';
import { Twitter } from '@finn/design-system/icons/twitter';
import { cn } from '@finn/ui-utils';
import { IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import openShareWindow, {
  shareBaseURL,
  SharePlatform,
} from '~/ui-modules/Hero/SocialSharing/openShareWindow';

type StyledProps = {
  scale: number;
};

function copyCurrentLink() {
  const inp = document.createElement('input');
  document.body.appendChild(inp);
  inp.value = `${shareBaseURL}${window.location.pathname}`;
  inp.select();
  document.execCommand('copy', false);
  inp.remove();
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(0.5, 0, 0, -0.5),
    transform: (props: StyledProps) =>
      props.scale ? `scale(${props.scale})` : 'none',
    transformOrigin: '0 0',
  },
  btn: {
    display: 'inline-block',
    margin: theme.spacing(0, 2, 0, 0),
  },
  copySuccessWrapper: {
    position: 'relative',
  },
  copySuccess: {
    position: 'absolute',
    padding: theme.spacing(0, 1.5),
    top: '10px',
  },
}));

interface IProps {
  scale?: number;
}

const SocialSharing: React.FC<IProps> = ({ scale = null }) => {
  const classes = useStyles({ scale });
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (showCopySuccess) {
      setTimeout(() => {
        setShowCopySuccess(false);
      }, 2000);
    }
  }, [showCopySuccess, setShowCopySuccess]);

  return (
    <div className={classes.wrapper}>
      <IconButton
        size="small"
        className={classes.btn}
        onClick={() => openShareWindow(SharePlatform.FACEBOOK)}
      >
        {<Facebook />}
      </IconButton>
      <IconButton
        size="small"
        className={classes.btn}
        onClick={() => openShareWindow(SharePlatform.TWITTER)}
      >
        {<Twitter />}
      </IconButton>
      <IconButton
        size="small"
        className={classes.btn}
        onClick={() => openShareWindow(SharePlatform.LINKEDIN)}
      >
        {<SoicialLinkedin />}
      </IconButton>
      <IconButton
        size="small"
        className={classes.btn}
        onClick={() => {
          copyCurrentLink();
          setShowCopySuccess(true);
        }}
      >
        {<Link />}
      </IconButton>
      <div className={classes.copySuccessWrapper}>
        {showCopySuccess ? (
          <span className={cn(classes.copySuccess, 'text-snow bg-black')}>
            {intl.formatMessage({
              id: 'general.guideLinkCopied',
            })}
          </span>
        ) : null}
      </div>
    </div>
  );
};
export default SocialSharing;
